import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import { Link } from "react-router-dom"
// import  Util  from "./Common/Util"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from 'moment';

// import images
import client from "../../assets/images/client.png";
import transactionAmount from "../../assets/images/transactionAmount.png";
import transactionPrice from "../../assets/images/transactionPrice.png";
import tradeHistory from "../../assets/images/거래내역.jpg";
import news from "../../assets/images/news.jpg";

import { useSelector } from 'react-redux';

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import ChartistGraph from 'react-chartist';

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next"
import { chartResponse, hazardChartResponse } from 'crud/auth.crud';
import { useEffect } from 'react';

import FadeIn from 'react-fade-in';

const style = { width: '50%', fontSize: 14 };

const clickedStyle = { backgroundColor: "#6210cc", color: "#ffffff" };

const ChartHistory = () => {
  const selector = useSelector(state => state)
  const newDate = new Date()
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id)
  const [modalShow, setModalShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [tableValue2, setTableValue2] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [size, setSize] = useState(4);
  const [totalElements, setTotalElements] = useState(4);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [letDate, setLetDate] = useState(newDate);
  const [month, setMonth] = useState(moment(newDate).format("MM"));
  const [day, setDay] = useState("");
  const [dayText, setDayText] = useState("");
  const [checkedDateType, setCheckedDateType] = useState("");
  const [year, setYear] = useState(moment(newDate).format("YYYY"));
  const [findingState, setFindingState] = useState('year')


  const [chartAxisXFromMonthly, setChartAxisXFromMonthly] = useState([]);
  const [chartAxisYFromMonthly, setChartAxisYFromMonthly] = useState([]);

  const [chartAxisXFromHazard, setChartAxisXFromHazard] = useState([]);
  const [chartAxisYFromHazard, setChartAxisYFromHazard] = useState([]);

  const dayData = ['일', '월', '화', '수', '목', '금', '토']


  const monthlyChartData = {
    labels: chartAxisXFromMonthly,
    series: [chartAxisYFromMonthly],
  }

  const yearlyChartData = {
    labels: [2023, 2024],
    series: [[368, 291]],
  }

  const cityChartData = {
    labels: ['서울', '부산', '대구', '인천', '광주', '대전', '울산', '세종', '경기', '강원', '충북', '충남', '전북', '전남', '경북', '경남', '제주'],
    series: [[0, 368, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
  }

  const searchClearChartData = {
    labels: ['2023-04', '2023-05', '2023-06'],
    series: [[368, 391, 434], [158, 236, 350]]
  }


  const hazardChartData = {
    labels: chartAxisXFromHazard,
    series: chartAxisYFromHazard,
  }

  const monthlyChartOptions = {
    low: 0,
    showArea: false,
    showLine: true,
    showPoint: true,
  }

  const yearlyChartOptions = {
    low: 0,
    showArea: true,
    showLine: true,
    showPoint: true,
  }

  const searchClearChartOptions = {
    low: 0,
    showArea: true,
    showLine: false,
    showPoint: false,
  }

  const cityChartOptions = {
    low: 0,
    showArea: true,
  }

  const hazardChartOptions = {
    low: 0,
    showArea: true,
    showLine: true,
    showPoint: true,
  }

  const pieResponsiveOptions = [
    ['screen and (min-width: 640px)', {
      chartPadding: 30,
      labelOffset: 100,
      labelDirection: 'explode',
      labelInterpolationFnc: function (value) {
        return value;
      }
    }],
    ['screen and (min-width: 1024px)', {
      labelOffset: 80,
      chartPadding: 20
    }]
  ];



  const getDataList = async () => {
    const response = await chartResponse();

    const content = response.data?.data || [];
    console.log("content >>>", content)


    var tempAxisXData = []
    var tempAxisYData = []


    content.map((item) => {

      var monthDate = item.month.substr(0, 7)

      tempAxisXData.push(monthDate)
      tempAxisYData.push(item.cnt)
    })

    tempAxisXData.push('2023-05')
    tempAxisXData.push('2023-06')

    tempAxisYData.push('391')
    tempAxisYData.push('434')

    setChartAxisXFromMonthly(tempAxisXData)
    setChartAxisYFromMonthly(tempAxisYData)

  }

  const getHazardChart = async () => {
    const response = await hazardChartResponse();

    const content = response.data?.data || [];
    console.log("content >>>", content)

    var tempAxisXData = []
    var tempAxisYData = []


    content.map((item) => {
      if (item.type === "1") {
        item.type = 'loadHazard'
      }
      tempAxisXData.push(item.type)
      tempAxisYData.push(item.cnt)
    })

    setChartAxisXFromHazard(tempAxisXData)
    setChartAxisYFromHazard(tempAxisYData)

  }


  useEffect(() => {
    getDataList();
    getHazardChart();
  }, [])

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
      // getDataList();
    }
  }, [isRefresh])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>차트 | Road Safety Total Operating Center </title>
        </MetaTags>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <h6 className="page-title">데이터 통계</h6>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">수집된 데이터들을 확인할 수 있습니다. </li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid>
          {/* <Breadcrumbs maintitle="웨버" title="관리대장" breadcrumbItem="차트" /> */}
          <FadeIn delay={500} transitionDuration={2000}>
            <Row>
              <Col xl={4} style={{ margin: "0 auto", minWidth: 541 }}>
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4" style={{ textAlign: 'center' }}>{findingState === 'year' ? '월별 배출량 차트' : '요일별 배출량 차트'}</h4> */}
                    <div className="btn-group" style={{ display: 'flex', width: '72%', margin: "36px auto 36px", height: 35 }}>
                      <Button
                        style={{ ...clickedStyle, ...style }}
                        type="button"
                        size="sm"
                        className="waves-effect"
                        onClick={() => {
                          setFindingState('year')
                        }}
                      >
                        월별 통계
                      </Button>
                    </div>

                    <Row>
                      <Col lg={12} style={{ margin: "24px auto" }}>
                        <div>
                          <ChartistGraph data={monthlyChartData} style={{ height: "300px" }} options={monthlyChartOptions} type={'Line'} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={4} style={{ margin: "0 auto", minWidth: 541 }}>
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4" style={{ textAlign: 'center' }}>{findingState === 'year' ? '월별 배출량 차트' : '요일별 배출량 차트'}</h4> */}
                    <div className="btn-group" style={{ display: 'flex', width: '72%', margin: "36px auto 36px", height: 35 }}>
                      <Button
                        style={{ ...clickedStyle, ...style }}
                        type="button"
                        size="sm"
                        className="waves-effect"
                        onClick={() => {
                          setFindingState('year')
                        }}
                      >
                        년도별 통계
                      </Button>
                    </div>

                    <Row>
                      <Col lg={12} style={{ margin: "24px auto" }}>
                        <div>
                          <ChartistGraph data={yearlyChartData} style={{ height: "300px" }} options={yearlyChartOptions} type={'Line'} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={4} style={{ margin: "0 auto", minWidth: 541 }}>
                <Card>
                  <CardBody>
                    <div className="btn-group" style={{ display: 'flex', width: '72%', margin: "36px auto 36px", height: 35 }}>

                      <Button
                        style={{ ...clickedStyle, ...style }}
                        type="button"
                        size="sm"
                        className="waves-effect"
                        onClick={() => {
                          setFindingState('month')
                        }}
                      >
                        지역별 통계
                      </Button>
                    </div>

                    <Row>
                      <Col lg={12} style={{ margin: "24px auto" }}>
                        <div>
                          <ChartistGraph data={cityChartData} style={{ height: "300px" }} options={cityChartOptions} type={'Bar'} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

            </Row>


            <Row>

              <Col xl={4} style={{ margin: "0 auto", minWidth: 741 }}>
                <Card>
                  <CardBody>
                    <div className="btn-group" style={{ display: 'flex', width: '72%', margin: "36px auto 36px", height: 35 }}>

                      <Button
                        style={{ ...clickedStyle, ...style }}
                        type="button"
                        size="sm"
                        className="waves-effect"
                        onClick={() => {
                          setFindingState('month')
                        }}
                      >
                        위험물 통계
                      </Button>
                    </div>

                    <Row>
                      <Col lg={12} style={{ margin: "24px auto" }}>
                        <div>
                          <ChartistGraph data={hazardChartData} style={{ height: "300px" }} options={hazardChartOptions} responsiveOptions={pieResponsiveOptions} type={'Pie'} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={4} style={{ margin: "0 auto", minWidth: 741 }}>
                <Card>
                  <CardBody>
                    <div className="btn-group" style={{ display: 'flex', width: '72%', margin: "36px auto 36px", height: 35 }}>

                      <Button
                        style={{ ...clickedStyle, ...style }}
                        type="button"
                        size="sm"
                        className="waves-effect"
                        onClick={() => {
                          setFindingState('month')
                        }}
                      >
                        탐지 대비 처리량
                      </Button>
                    </div>

                    <Row>
                      <Col lg={12} style={{ margin: "24px auto" }}>
                        <div>
                          <ChartistGraph data={searchClearChartData} style={{ height: "300px" }} options={searchClearChartOptions} type={'Line'} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>



            </Row>
          </FadeIn>

        </Container>
      </div>

    </React.Fragment>
  )
}

ChartHistory.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(ChartHistory)
