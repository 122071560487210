import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Link, useLocation, useHistory } from "react-router-dom"

import MapContext from './MapContext'
import 'ol/ol.css'
import { Map as OlMap, View } from 'ol'
import { Control, defaults as defaultControls, FullScreen } from 'ol/control'
import { fromLonLat, get as getProjection } from 'ol/proj'
import { XYZ } from 'ol/source'
import { Cluster, Vector, TileWMS, Tile } from 'ol/source.js';
import sourceTile from 'ol/source/Tile.js';
import { Group as LayerGroup, Heatmap as HeatmapLayer, Tile as TileLayer, } from 'ol/layer.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Select from 'ol/interaction/Select.js';
import { AssertionError, Collection, Disposable, Geolocation, Graticule, Image, ImageBase, ImageCanvas, ImageTile, Kinetic, MapBrowserEvent, MapBrowserEventHandler, MapEvent, Object, Observable, Overlay, TileCache, TileQueue, TileRange, VERSION, VectorRenderTile, VectorTile, getUid } from 'ol'
import {
  DragRotateAndZoom,
  defaults as defaultInteractions,
} from 'ol/interaction'
import {
  Circle as CircleStyle,
  Fill,
  Text,
  Icon,
  Stroke,
  Style,
} from 'ol/style.js';
import { altKeyOnly, altShiftKeysOnly, always, click, doubleClick, focus, focusWithTabindex, mouseActionButton, mouseOnly, never, noModifierKeys, penOnly, platformModifierKeyOnly, pointerMove, primaryAction, shiftKeyOnly, singleClick, targetNotEditable, touchOnly } from 'ol/events/condition'
import { Interaction } from 'ol/interaction'
import { bbox, tile } from 'ol/loadingstrategy.js'
import axios from 'axios';
import { newsResponse } from 'crud/auth.crud';
import $ from "jquery";





const MapDetail = ({ children }) => {
  const [mapObj, setMapObj] = useState({})
  const location = useLocation()
  console.log("location >>>", location)
  const history = useHistory()

  const handleClick = (page, idx) => {
    const url = {
      pathname: `/${page}`,
      state: {
        id: idx,
      }
    }
    console.log("url :::", url)
    console.log("idx :::", idx)


    history.push(url);
  }

  class RotateNorthControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
      const options = opt_options || {};

      const button = document.createElement('button');
      button.innerHTML = '☰';

      const tree = document.getElementById('layertree');


      const element = document.createElement('div')
      element.className = 'rotate-north ol-unselectable ol-control';
      element.appendChild(button);
      element.appendChild(tree);

      super({
        element: element,
        target: options.target,
      });

      button.addEventListener('click', this.handleRotateNorth.bind(), false);
    }

    handleRotateNorth() {
      console.log("now on")
      var t = document.getElementById("layertree");

      console.log(t.style)

      if (t.style.display !== 'none') {
        t.style.display = 'none'
      } else {
        t.style.display = 'inline'
      }
    }

  }

  useLayoutEffect(() => {
    const heatmapLayerGroup = new LayerGroup({})
    const clusterLayerGroup = new LayerGroup({})



    const map = new OlMap({
      controls: defaultControls({ zoom: false, rotate: false }).extend([
        new FullScreen(),
        new RotateNorthControl()
      ]),
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      layers: [
        new TileLayer({
          source: new XYZ({ // 인증키 관리자 -> dylan
            url: 'http://api.vworld.kr/req/wmts/1.0.0/562DFD04-9C7D-3374-9EB5-F6F3A9C9720D/Base/{z}/{y}/{x}.png',
          }),
        }),
        new VectorLayer({
          source: new VectorSource({
            url: 'https://rstoc.ropiklabs.com/media/26busan.geojson',
            // url: 'http://141.164.46.165:12080/geoserver/ropik/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ropik%3Actp_rvn&maxFeatures=50&srsname=EPSG:3857&outputFormat=application%2Fjson',
            // url: 'http://141.164.46.165:12080/geoserver/ropik/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ropik%3Aemd&srsname=EPSG:3857&outputFormat=application%2Fjson', 
            format: new GeoJSON(),
          }),
          style: new Style({
            stroke: new Stroke({
              color: 'grey',
              width: 1
            }),
            fill: new Fill({
              color: 'rgba(255,255,255,0.4)'
            })
          }),
          // background:'rgba(0, 0, 0, 1)'

        }),
        heatmapLayerGroup,
        clusterLayerGroup,
      ],
      target: 'map',
      view: new View({
        projection: getProjection('EPSG:3857'), //구글지도 쓸 경우 변경하여야 함
        center: fromLonLat(
          [129.0750223, 35.179816], // 추후 평균값 입력
          getProjection('EPSG:3857')
        ),
        zoom: 11.3,
        minZoom: 9
      }),
    })
    setMapObj({ map })

    function bindInputs(layerid, layer) {
      const visibilityInput = $(layerid + ' input.visible');
      visibilityInput.on('change', function () {
        layer.setVisible(this.checked);
      });
      visibilityInput.prop('checked', layer.getVisible());

      const opacityInput = $(layerid + ' input.opacity');
      opacityInput.on('input', function () {
        layer.setOpacity(parseFloat(this.value));
      });
      opacityInput.val(String(layer.getOpacity()));
    }
    function setup(id, group) {
      group.getLayers().forEach(function (layer, i) {
        const layerid = id + i;
        bindInputs(layerid, layer);
        if (layer instanceof LayerGroup) {
          setup(layerid, layer);
        }
      });
    }
    setup('#layer', map.getLayerGroup());


    // ******************************************************************************************
    // ******************************************************************************************
    var markerList = []

    async function getHazardData() {
      try {
        const response = await newsResponse();
        if (response.status === 200) {
          console.log(response.data)
          response.data.forEach(element => {

            // console.log(element)

            var changeHostStr = element.image.replace("http://127.0.0.1:12070", "https://rstoc.ropiklabs.com")
            var dataIdx = element.id

            const marker = new Feature({
              type: 'icon',
              geometry: new Point([element.longitude, element.latitude]).transform('EPSG:4326', 'EPSG:3857'),
              data: changeHostStr,
              idx: dataIdx
            });

            markerList.push(marker)

            const styles = {
              'icon': new Style({
                // image: new Icon({
                //   anchor: [0.1, 1],
                //   src: element.image,
                // }),
                image: new CircleStyle({
                  radius: 7,
                  fill: new Fill({ color: 'black' }),
                  stroke: new Stroke({
                    color: 'white',
                    width: 2,
                  }),
                }),
              }),
            };

            const vectorLayer = new VectorLayer({
              source: new VectorSource({
                features: [marker],
              }),
              style: function (feature) {
                return styles[feature.get('type')];
              },
            });

            // map.addLayer(vectorLayer);

          });

          const source = new VectorSource({
            features: markerList,
          });

          const heatLayer = new HeatmapLayer({
            source: source,
            blur: 100,
            radius: 10
          });



          const clusterSource = new Cluster({
            distance: parseInt(20, 10),
            minDistance: parseInt(10, 10),
            source: source,
          });

          const styleCache = {};
          const clusters = new VectorLayer({
            source: clusterSource,
            style: function (feature) {
              const size = feature.get('features').length;
              let style = styleCache[size];
              if (!style) {
                style = new Style({
                  image: new CircleStyle({
                    radius: 10,
                    stroke: new Stroke({
                      color: '#fff',
                    }),
                    fill: new Fill({
                      color: '#000',
                    }),
                  }),
                  text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                      color: '#fff',
                    }),
                  }),
                });
                styleCache[size] = style;
              }
              return style;
            },
          });


          // map.addLayer(heatLayer);
          // map.addLayer(clusters);

          heatmapLayerGroup.getLayers().array_.push(heatLayer)
          clusterLayerGroup.getLayers().array_.push(clusters)

          map.addLayer(heatmapLayerGroup)
          map.addLayer(clusterLayerGroup)



        }
      } catch (error) {
        console.error(error);
      }
    }

    getHazardData()
    //현재는 모든 위험물 데이터를 불러온다
    //2차탐지 서버가 추가되면, 2차탐지완료시 지역 행정코드도 발급하여 위치 확인한다.

    // ******************************************************************************************
    // ******************************************************************************************





    // ******************************************************************************************
    // ******************************************************************************************


    // ******************************************************************************************
    // ******************************************************************************************

    // ******************************************************************************************
    // ******************************************************************************************
    // 각 행정구역 마우스 오버시 하이라이팅
    // 마우스 오버시 스타일 지정
    // var selectPointerMove = new Select({
    //     condition: pointerMove,
    //     style: new Style({
    //         stroke: new Stroke({
    //             color: 'white',
    //             width: 0
    //         }),
    //         fill: new Fill({
    //             color: 'rgba(0,0,0,0)'
    //         })
    //     })
    // });

    // console.log("selectPointerMove :: ",selectPointerMove)

    // // // interaction 추가
    // map.addInteraction(selectPointerMove);

    // ******************************************************************************************
    // ******************************************************************************************




    // ******************************************************************************************
    // ******************************************************************************************
    map.on('click', function (evt) {
      // 해당지도의 feature객체를 가져온다
      var feature = map.forEachFeatureAtPixel(evt.pixel,
        function (feature) {
          console.log("feature option", feature)
          return feature;
        }
      );

      //feature가 빈값이 아닐때 프로퍼티값을 확인 ... 이 if구문에서는 법정동 코드를 체크함.
      //추후 자신의 행정구역 클릭시 마커 추가 예정
      //api도 지역별 분류 예정 (2차탐지 이후 분류)
      if (feature !== undefined) {
        var values = feature["values_"]
        //feature내부의 value값에 features값이 있다면 ... 이 if구문에서의 features는 마커를 의미함.
        if (values["features"] !== undefined) {
          //배열내 데이터가 하나일 경우 0번 데이터출력
          if (values["features"].length === 1) {
            console.log("vvvvv ::: ", values["features"][0].values_)
            // window.open(values["features"][0].values_.data, "_blank");
            handleClick('detail', values["features"][0].values_.idx)
          } else {
            //임시로 대충 출력
            handleClick('detail', values["features"][0].values_.idx)
          }
        }

      }


    });

    // ******************************************************************************************
    // ******************************************************************************************

    map.on('loadstart', function () {
      map.getTargetElement().classList.add('spinner');
    });

    map.on('loadend', function () {
      map.getTargetElement().classList.remove('spinner');
    });

    return () => map.setTarget(undefined)
  }, [])

  return <MapContext.Provider value={mapObj}>{children}</MapContext.Provider>
}

export default MapDetail
