import _ from "lodash";
import moment from 'moment';
import persistor from "index"
import { SET_AUTH, CLEAR_AUTH } from "../store/auth/profile/actionTypes"
import { RE_LOGIN_USER, LOGOUT_USER } from "../store/auth/login/actionTypes"
import { CLEAR_DATA } from "../store/auth/data/actionTypes"
import { API_URL } from "../crud/auth.crud"

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { accessToken }
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }      
      return config;
    },
    err => Promise.reject(err)    
  );


  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response,
      } = error;
      if (response?.status === 401) {
        if(config?.url.indexOf('/token/refresh/') >= 0){                 
            localStorage.removeItem('ACCESS_TOKEN')
            localStorage.removeItem('authUser');
            await store.dispatch({type: CLEAR_AUTH})
            await store.dispatch({type: CLEAR_DATA})
            await store.dispatch({type: LOGOUT_USER})
            if(config?.url.indexOf('/token/refresh/') >= 0){
              setTimeout(()=>{
                window.location.href = '/login'  
              }, 2000)
            }
            return Promise.reject(error);             
        }
        if(window.location.href.indexOf('/login') >= 0){
          localStorage.removeItem('ACCESS_TOKEN')
          localStorage.removeItem('authUser');
          await store.dispatch({type: CLEAR_AUTH})
          await store.dispatch({type: CLEAR_DATA})
          await store.dispatch({type: LOGOUT_USER})
          
          axios.defaults.headers.common.Authorization = '';
          return Promise.reject(error);    
        }               
        const originalRequest = config;                
        let authData = await store.getState()?.auth;
        // token refresh 요청      
        // config.headers.Authorization = `Bearer ${refreshToken}`;
        try {                    
          const { data } = await axios.post(API_URL+'/token/refresh/', { refresh: authData?.refreshToken || '' });
          authData.accessToken = data?.access
          await store.dispatch({type: RE_LOGIN_USER, payload : authData})
          localStorage.setItem("authUser", JSON.stringify(authData?.user))          
          // 새로운 토큰 저장                    
          // axios.defaults.headers.common.Authorization = `Bearer ${data?.access}`;
          // originalRequest.headers.Authorization = `Bearer ${data?.access}`;
          // 401로 요청 실패했던 요청 새로운 accessToken으로 재요청
          return await axios(originalRequest);
        } catch (e) {                       
          if (window.location.href.indexOf('/login') < 0) {                      
            localStorage.removeItem('ACCESS_TOKEN')
            localStorage.removeItem('authUser');
            await store.dispatch({type: CLEAR_AUTH})
            await store.dispatch({type: CLEAR_DATA})
            await store.dispatch({type: LOGOUT_USER})            
            axios.defaults.headers.common.Authorization = '';
            if(config?.url.indexOf('/token/refresh/') >= 0){
              setTimeout(()=>{
                window.location.href = '/login'  
              }, 2000)
            }
            // await persistor.purge()
            //window.location.href = '/login'
          }
        }
      }
      return Promise.reject(error);
    }
  );
}