import React, { useState } from "react"
import { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { contractDelete, getSellProductDetail, postTradeRequest, sellComplete1, sellComplete2, sellComplete3, sellComplete4, sellDelete1, sellDelete2, sellDelete3 } from 'crud/auth.crud';

import { Link, useLocation, useHistory } from "react-router-dom"
import moment from "moment";

import empty from "../../assets/images/empty.png"
import _ from "lodash";

import FadeIn from 'react-fade-in';

import { useSelector } from "react-redux";
const SellDetailScreen = () => {
  const selector = useSelector(state => state)

  const location = useLocation()
  console.log("location >>>", location)
  const history = useHistory()

  // const [form, setForm] = useState('detail')
  // const [type, setType] = useState(selector.auth.user?.type || "")
  const [id, setId] = useState(location.pathname);
  // const [myCompany, setMyCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group);
  // const [myProfile, setMyProfile] = useState(selector.auth?.user)
  // const [otherCompany, setOtherCompany] = useState('');
  // const [signFile, setSignFile] = useState(selector.auth.user.member_file.filter(item => item.category === 'signFile')?.[0] || null);
  // const [otherSignFile, setOtherSignFile] = useState(null);
  const [data, setData] = useState({});
  // const [haveAuth, setHaveAuth] = useState(false);
  // const [isReceipt, setIsReceipt] = useState(false);
  // const [onUpload, setOnUpload] = useState(false);
  // const [deleteOn, setDeleteOn] = useState(false);
  // const [contractCompanyGroupId, setContractCompanyGroupId] = useState('')
  // const [companyList, setCompanyList] = useState([])
  // const [signList, setSignList] = useState([])
  // const [tradeRequest, setTradeRequest] = useState({})
  // const [isMe, setIsMe] = useState(false)
  // const [chatList, setChatList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  // const [modalShow, setModalShow] = useState(false)
  // const [requestQty, setRequestQty] = useState('')


  const getData = async () => {
    const response = await getSellProductDetail(location.state.id);
    if (response.status === 200) {
      setData(response.data)
      console.log(response.data)
    }
    setIsLoading(false)
  }


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    getData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>위험물 상세 | Road Safety Total Operating Center </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs maintitle="웨버" title="거래소" breadcrumbItem="제품 상세" /> */}

          <Row>

            <Col xs="12">
              {/* Render Email SideBar */}
              {/* <EmailSideBar /> */}
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody style={{ padding: 48 }}>
                    <FadeIn delay={30} transitionDuration={800}>
                      <div onClick={() => { window.history.back() }}
                        style={{ padding: '0.4rem 1rem', borderRadius: '0.2rem', marginBottom: 48, fontSize: 16 }}
                        className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success"
                      >뒤로가기
                      </div>
                      <>

                        <FadeIn delay={30} transitionDuration={800}>
                          <div style={{ display: "flex", marginBottom: 50, height: 35 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>탐지 번호</p>
                            <p style={{ fontSize: 16, lineHeight: '35px' }}>{data.id}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50, height: 600 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>탐지 화상</p>
                            {
                              <img src={data.image ? data.image.replace("http://127.0.0.1:12070", "https://rstoc.ropiklabs.com")  : data.image } style={{ fontSize: 16, lineHeight: '35px', padding: 3, border: "1px solid #ced4da" }} />
                            }
                          </div>
                          <div style={{ display: "flex", marginBottom: 50, height: 35 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>탐지 타입</p>
                            <p style={{ fontSize: 16, lineHeight: '35px' }} >{data.classification_type}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>탐지 갯수</p>
                            <p style={{ fontSize: 16, lineHeight: '35px', width: 480 }} >{data.detect_count}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50, height: 35 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>탐지 상태</p>
                            <p style={{ fontSize: 16, lineHeight: '35px', width: 480, color: data.status == '2차 탐지 완료' ? 'blue' : data.status == '1차 탐지 완료' ? 'red' : '#6210cc' }} >{data.status}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50, height: 35 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>위도</p>
                            <p style={{ fontSize: 16, lineHeight: '35px' }}>{data.latitude}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50, height: 35 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>경도</p>
                            <p style={{ fontSize: 16, lineHeight: '35px' }}>{data.longitude}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50, height: 35 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>탐지일시</p>
                            <p style={{ fontSize: 16, lineHeight: '35px' }} >{data.create_at}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50, height: 35 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>최근 수정일</p>
                            <p style={{ fontSize: 16, lineHeight: '35px' }} >{data.update_at}</p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 50 }}>
                            <p style={{ width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px" }}>관할 구역</p>
                            <p style={{ fontSize: 16, lineHeight: '35px', width: 480 }} >{data.city == '' ? '부산광역시' : ''}</p>
                          </div>
                        </FadeIn>

                      </>
                    </FadeIn>

                  </CardBody>

                </Card>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default SellDetailScreen
