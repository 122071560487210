import React from "react"
import { Redirect } from "react-router-dom"

//Management
import ChartHistory from "../pages/Management/ChartHistory"
// import TradeHistory from "../pages/Management/TradeHistory"
// import AllbaroProcess from "../pages/Management/AllbaroProcess"
// import CollectHistory from "../pages/Management/CollectHistory"
// import ExchangePlatform from "../pages/Management/ExchangePlatform"
import DetailScreen from "../pages/Management/DetailScreen"
// import BuyDetailScreen from "../pages/Management/BuyDetailScreen"
import BuyRequestWrite from "../pages/Management/BuyRequestWrite"
// import WearingHistory from "../pages/Management/WearingHistory"
// import BusinessList from "../pages/Management/BusinessList"
// import DisposeHistory from "pages/Management/DisposeHistory"
// import DisposeScreen from "pages/Management/DisposeScreen"
// import ProcessHistory from "pages/Management/ProcessHistory"
// import EachSiteHistory from "pages/Management/EachSiteHistory"
// import CompanyTotalList from "pages/Management/CompanyTotalList"


// ###########################################################################################
// 로그인정보가 없어도 접속 가능 페이지
// ###########################################################################################
import Main from "../pages/Main/Main"
import Login from "../pages/Authentication/Login"
// import Logout from "../pages/Authentication/Logout"
// import Terms from "../pages/Authentication/Terms"
// ###########################################################################################
// ###########################################################################################

// Dashboard
import Dashboard from "../pages/Dashboard/index"


// ###########################################################################################
// 로그인정보가 없어도 접속 가능 페이지
// ###########################################################################################
const userRoutes = [
  { path: "/main", component: Main },
  { path: "/dashboard", component: Dashboard },

  //Management
  { path: "/chart-history", component: ChartHistory },
  // { path: "/trade-history", component: TradeHistory },
  // { path: "/allbaro-process", component: AllbaroProcess },
  // { path: "/collect-history", component: CollectHistory },
  // { path: "/exchange-platform", component: ExchangePlatform },
  { path: "/detail-screen", component: DetailScreen },
  // { path: "/buy-detail-screen", component: BuyDetailScreen },
  { path: "/buy-request-write", component: BuyRequestWrite },
  // { path: "/wearing-history", component: WearingHistory },
  // { path: "/business-list", component: BusinessList },
  // { path: "/process-history", component: ProcessHistory },
  // { path: "/dispose-history", component: DisposeHistory },
  // { path: "/dispose-screen", component: DisposeScreen },
  // { path: "/each-site-history", component: EachSiteHistory },
  // { path: "/company-total-list", component: CompanyTotalList },
  
  // 루트의 끝점
  { path: "/", exact: true, component: () => <Redirect to="/main" /> },
]
// ###########################################################################################
// ###########################################################################################




// ###########################################################################################
// 로그인정보가 없어도 접속 가능 페이지
// ###########################################################################################
const authRoutes = [
  { path: "/main", component: Main },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: Login },
  { path: "/detail", component: DetailScreen },
  { path: "/chart", component: ChartHistory },
  { path: "/test", component: BuyRequestWrite },
]
// ###########################################################################################
// ###########################################################################################

export { userRoutes, authRoutes }
