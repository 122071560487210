import PropTypes from 'prop-types'
import React,{useState} from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import { useSelector } from 'react-redux';

import Emitter from './Emitter';

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  const selector = useSelector(state => state)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>RSTOC</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">위험물 현황리스트</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">지역 내 위험물리스트 입니다. </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Emitter/>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
