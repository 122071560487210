import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const selector = useSelector(state => state)

  const [type, setType] = useState(selector.auth.user?.type || "")
  const [detailType, setDetailType] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.type || "")

  const emitter = [
    [
      { "link": "/chart-history", "text": "차트" },
      { "link": "/trade-history", "text": "거래내역" },
    ],
    [
      { "link": "/emitter-bill", "text": "청구서" },
      { "link": "/settlement-details", "text": "정산/납부 내역" },
    ],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
      { "link": "/contract-management-screen", "text": "계약서 관리" },
      { "link": "/business-management-screen", "text": "사업장 관리" },
    ]
  ]

  const emitterMany = [
    [
      { "link": "/chart-history", "text": "차트" },
      { "link": "/trade-history", "text": "거래내역" },
      { "link": "/allbaro-process", "text": "올바로 처리내역" },
    ],
    [
      { "link": "/emitter-bill", "text": "청구서" },
      { "link": "/settlement-details", "text": "정산/납부 내역" },
    ],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
      { "link": "/contract-management-screen", "text": "계약서 관리" },
      { "link": "/business-management-screen", "text": "사업장 관리" },
      { "link": "/report-management-screen", "text": "신고사항 관리" },
    ]
  ]

  const emitterMulti = [
    [],
    [],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
      { "link": "/contract-management-screen", "text": "계약서 관리" },
      { "link": "/business-management-screen", "text": "내 사업장 관리" },
      { "link": "/business-list-management-screen", "text": "사업장 관리" },
    ]
  ]

  const emitterTotal = [
    [],
    [],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
      { "link": "/contract-management-screen", "text": "계약서 관리" },
      { "link": "/business-management-screen", "text": "내 사업장 관리" },
      { "link": "/business-company-total-list", "text": "사업장 관리" },
      { "link": "/report-management-screen", "text": "신고사항 관리" },
    ]
  ]

  const collection = [
    [
      { "link": "/collect-history", "text": "수거내역" },
      { "link": "/dispose-history", "text": "인계내역" },
      { "link": "/allbaro-process", "text": "올바로 처리내역" },
    ],
    [
      { "link": "/collection-bill", "text": "청구서" },
      { "link": "/settlement-details", "text": "정산/납부 내역" },
    ],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
      { "link": "/contract-management-screen", "text": "계약서 관리" },
      { "link": "/business-management-screen", "text": "사업장 관리" },
      { "link": "/report-management-screen", "text": "신고사항 관리" },
      { "link": "/driver-management-screen", "text": "운반기사 관리" },
      { "link": "/waver-bar-management-screen", "text": "웨버바 관리" },
      { "link": "/waste-bin-management-screen", "text": "쓰레기통 관리" },
      { "link": "/car-number-management-screen", "text": "차량번호 관리" },
    ]
  ]

  const treatment = [
    [
      { "link": "/wearing-history", "text": "인계내역" },
      { "link": "/process-history", "text": "처리내역" },
      { "link": "/allbaro-process", "text": "올바로 처리내역" },
    ],
    [],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
      { "link": "/contract-management-screen", "text": "계약서 관리" },
      { "link": "/business-management-screen", "text": "사업장 관리" },
      { "link": "/report-management-screen", "text": "신고사항 관리" },
      { "link": "/platform-product-screen", "text": "거래소 상품 관리" },
      { "link": "/my-product-screen", "text": "내 상품 관리" },
    ]
  ]

  const demand = [
    [],
    [],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
      { "link": "/business-management-screen", "text": "사업장 관리" },
      { "link": "/report-management-screen", "text": "신고사항 관리" },
      { "link": "/my-product-screen", "text": "내 상품 관리" },
    ]
  ]

  const driver = [
    [],
    [],
    [],
    [
      { "link": "/profile-screen", "text": "회원정보" },
      { "link": "/service-center-screen", "text": "고객센터" },
    ]
  ]

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
    console.log("detailType >>>", detailType)
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">

{/* 단일배출자 */}
            {type === 'emitter' && detailType !== 'total' && detailType !== 'multi' && detailType !== 'many' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-zip"></i>
                    <span>{props.t("관리대장")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitter[0].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-clipboard"></i>
                    <span>{props.t("청구서")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitter[1].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <Link to="/TMS" className="waves-effect">
                    <i className="ti-location-pin"></i>
                    <span>{props.t("TMS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitter[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

{/* 다량배출자 */}
            {type === 'emitter' && detailType === 'many' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-zip"></i>
                    <span>{props.t("관리대장")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitterMany[0].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-clipboard"></i>
                    <span>{props.t("청구서")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitterMany[1].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <Link to="/TMS" className="waves-effect">
                    <i className="ti-location-pin"></i>
                    <span>{props.t("TMS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitterMany[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

{/* 다수사업장 배출자 */}
            {type === 'emitter' && detailType === 'multi' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/business-list" className="waves-effect">
                    <i className="ti-zip"></i>
                    <span>{props.t("관리대장")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/report-list" className="waves-effect">
                    <i className="ti-clipboard"></i>
                    <span>{props.t("청구서")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/TMS" className="waves-effect">
                    <i className="ti-location-pin"></i>
                    <span>{props.t("TMS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitterMulti[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

{/* 사업장배출자 */}
            {type === 'emitter' && detailType === 'total' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/company-total-list" className="waves-effect">
                    <i className="ti-zip"></i>
                    <span>{props.t("관리대장")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/report-total-list" className="waves-effect">
                    <i className="ti-clipboard"></i>
                    <span>{props.t("청구서")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/TMS" className="waves-effect">
                    <i className="ti-location-pin"></i>
                    <span>{props.t("TMS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {emitterTotal[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

{/* 운반기사 */}
            {type === 'collection' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-zip"></i>
                    <span>{props.t("관리대장")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {collection[0].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-clipboard"></i>
                    <span>{props.t("청구서")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {collection[1].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <Link to="/TMS" className="waves-effect">
                    <i className="ti-location-pin"></i>
                    <span>{props.t("TMS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {collection[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

{/* 처리장 */}
            {type === 'treatment' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-zip"></i>
                    <span>{props.t("관리대장")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {treatment[0].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <Link to="/settlement-details" className="waves-effect">
                    <i className="ti-clipboard"></i>
                    <span>{props.t("정산/납부 내역")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {treatment[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

{/* 수요업체 */}
            {type === 'demand' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/exchange-platform" className="waves-effect">
                    <i className="ti-money"></i>
                    <span>{props.t("거래소")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {demand[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

{/* 운반기사 */}
            {type === 'driver' &&
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("웨버 홈")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/dispose-screen" className="waves-effect">
                    <i className="ti-zip"></i>
                    <span>{props.t("인계내역")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/TMS" className="waves-effect">
                    <i className="ti-location-pin"></i>
                    <span>{props.t("TMS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-heart"></i>
                    <span>{props.t("마이웨버")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {driver[3].map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{props.t(item.text)}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            }

        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
