import PropTypes from 'prop-types'
import React,{useState, useContext} from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import  Util  from "./Common/Util"

import moment from 'moment';

// import images
import client from "../../assets/images/client.png";
import transactionAmount from "../../assets/images/transactionAmount.png";
import transactionPrice from "../../assets/images/transactionPrice.png";
import tradeHistory from "../../assets/images/거래내역.jpg";
import news from "../../assets/images/news.jpg";

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import ChartistGraph from 'react-chartist';

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useLocation, useHistory } from "react-router-dom"

import "chartist/dist/scss/chartist.scss";
import "./animate.css";

import { useSelector } from 'react-redux';
import defaultImg from "../../assets/images/default.png"

//i18n
import { withTranslation } from "react-i18next"
import { dashboardResponse, mainResponse, newsResponse, postProcessingWearing, postWearing } from 'crud/auth.crud';
import { useEffect } from 'react';

import MapContext from '../Maps/MapContext'
import MapDetail from '../Maps/MapDetail'

import FadeIn from 'react-fade-in';

const Dashboard = props => {
  const selector = useSelector(state => state)
  const { map } = useContext(MapContext)
  const newDate = new Date()
  const nextDate = new Date()
  const history = useHistory()
  const location = useLocation()

  const [isFetching, setIsFetching] = useState(false)
  const [isListEnd, setIsListEnd] = useState(false)
  const [newYear, setNewYear] = useState(moment(newDate).format("YYYY"))
  const [fixedYear, setfixedYear] = useState(moment(newDate).format("YYYY")) //최신으로 고정되는 년도값
  const [newDateText, setNewDateText] = useState(moment(newDate).format('DD'))
  const [newMonth, setNewMonth] = useState(moment(newDate).format("MM"))
  const [page, setPage] = useState(1)
  const [companyCount, setCompanyCount] = useState();
  const [collectSum, setCollectSum] = useState();
  const [collectMoneySum, setCollectMoneySum] = useState();
  const [newsList, setNewsList] = useState();
  const [isRefresh, setIsRefresh] = useState(false)
  const [dataList, setDataList] = useState([])
  const [monthDateText, setMonthDateText] = useState(moment(newDate).format("MM/DD")+'일자')
  const [maxDate, setMaxDate] = useState(new Date(moment(newDate).format("YYYY"), moment(newDate).format("MM"),0).getDate())
  const [thisMonth, setThisMonth] = useState() //이달의 배출량
  const [prevMonth, setPrevMonth] = useState() //저번달의 배출량
  const [user, setUser] = useState(selector.auth?.user)
  const [myWearingSum, setMyWearingSum] = useState(0)
  const [myProcessSum, setMyProcessSum] = useState(0)
  const [maxProcessWearing, setMaxProcessWearing] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.capacity || 500)
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM-DD') + ' 00:00:00')
  const [endDate, setEndDate] = useState(moment(nextDate.setDate(nextDate.getDate()+1)).format('YYYY-MM-DD') + ' 00:00:00')

  const getNewsList = async () => {
    try {
      const response = await newsResponse();
      if (response.status === 200) {
        // console.log(response.data)
        const newsList = response.data
        setNewsList(newsList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (page,idx) => {
    const url = {
    pathname : `/${page}`,
    state:{
    	id:idx,
    }
    }    
    console.log("url :::",url)
    console.log("idx :::",idx)
    

    history.push(url);
  }

  useEffect(() => {
    getNewsList();    
    // getDashboardData();    
    // getFixedData();
    // getWearingData()
    // console.log("설렉터", selector)
  }, [])

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //     <MetaTags>
    //       <title>홈 | Road Safety Total Operating Center </title>
    //     </MetaTags>
    //     <Container fluid>
    //       <div className="page-title-box">
    //         <Row className="align-items-center">
    //           <Col md={8}>
    //             <h6 className="page-title">웨버 홈</h6>
    //             <ol className="breadcrumb m-0">
    //             </ol>
    //           </Col>

    //         </Row>
    //       </div>
        <>


    
    
          

          <div id="map" style={{paddingTop: 0, marginBottom: 10, width: '100%', height: '600px',}}>
            <MapDetail/>
          </div>

          {/* 여기 부터 테스트  */}
          <div id="layertree" style={{backgroundColor: 'white', display:'none'}}>
      <ul>
        <li>
          <span>배경지도</span>
          <fieldset id="layer0">
            <input id="visible0" class="visible" type="checkbox"/>
            <input class="opacity" type="range" min="0" max="1" step="0.01" />
          </fieldset>
        </li>
        <li>
          <span>행정구역</span>
          <fieldset id="layer1">
          <input id="visible1" class="visible" type="checkbox"/>
          <input class="opacity" type="range" min="0" max="1" step="0.01"/>
          </fieldset>
        </li>
        <li>
          <span>히트맵 </span>
          <fieldset id="layer2">
          <input id="visible1" class="visible" type="checkbox"/>
          {/* <input class="opacity" type="range" min="0" max="1" step="0.01"/> */}
          </fieldset>
        </li>
        <li>
          <span>위험물 </span>
          <fieldset id="layer3">
          <input id="visible1" class="visible" type="checkbox"/>
          <input class="opacity" type="range" min="0" max="1" step="0.01"/>
          </fieldset>
        </li>
      </ul>
    </div>

    {/* 여기까지 테스트 */}
    <FadeIn transitionDuration={2000}>
          <Row>
            {newsList && newsList.map((item, index) => { 
                // console.log("item....",item)

                if(index > 54) {
                  return (
                    <Col xl={4} md={6} key={index}>
                      <a onClick={()=>{handleClick('detail',item.id)}} style={{color: '#5b626b'}}>
                      <Card className="directory-card">
                        <CardBody>
                          <div className="d-flex">
                            <img
                              src={item.image.replace("http://127.0.0.1:12070", "https://rstoc.ropiklabs.com")}
                              alt="사진"
                              className="img-fluid img-thumbnail avatar-lg"
                              style={{width: 6.5 + 'rem'}}
                              onError={(e) => e.target.src = defaultImg}
                            />
                            <div className="flex-1 ms-3">
                              <h5 className="font-size-18 mt-0 mb-1" >
                              {item.status}
                              </h5>
                              <p className="mb-0">{item.create_at.substr(0, 10)} {item.create_at.substr(11, 8)}</p>
                              <p className="font-size-12 mb-2">{item.classification_type}</p>
                              
                            </div>
                          </div>
                          <hr />
                          <p className="mb-0">
                            <b>{item.content}</b>{" "}
                            <p className="font-size-12 mb-2">{item.comment}</p>
                          </p>
                        </CardBody>
                      </Card>
                      </a>
                    </Col>
                  )
                } else {
                  return 
                }
              })
            }
          </Row>
          </FadeIn>
        </>
    //     </Container>
    //   </div>

    // </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)

// import PropTypes from 'prop-types'
// import React,{useState} from "react"
// import MetaTags from 'react-meta-tags';
// import {
//   Container,
//   Row,
//   Col,
//   Button,
//   Card,
//   CardBody,
//   Input,
//   Dropdown,
//   DropdownToggle,
//   DropdownItem,
//   DropdownMenu
// } from "reactstrap"
// import { Link } from "react-router-dom"
// import  Util  from "./Common/Util"

// import moment from 'moment';

// // import images
// import client from "../../assets/images/client.png";
// import transactionAmount from "../../assets/images/transactionAmount.png";
// import transactionPrice from "../../assets/images/transactionPrice.png";
// import trade-history from "../../assets/images/거래내역.jpg";
// import news from "../../assets/images/news.jpg";

// // Charts
// import LineAreaChart from "../AllCharts/apex/lineareachart";
// import ChartistGraph from 'react-chartist';

// import "chartist/dist/scss/chartist.scss";

// import { useSelector } from 'react-redux';

// //i18n
// import { withTranslation } from "react-i18next"
// import { dashboardResponse, mainResponse, newsResponse } from 'crud/auth.crud';
// import { useEffect } from 'react';

// const Dashboard = props => {
//   const selector = useSelector(state => state)
//   const newDate = new Date()

//   const [isFetching, setIsFetching] = useState(false)
//   const [isListEnd, setIsListEnd] = useState(false)
//   const [newYear, setNewYear] = useState(moment(newDate).format("YYYY"))
//   const [fixedYear, setfixedYear] = useState(moment(newDate).format("YYYY")) //최신으로 고정되는 년도값
//   const [newDateText, setNewDateText] = useState(moment(newDate).format('DD'))
//   const [newMonth, setNewMonth] = useState(moment(newDate).format("MM"))
//   const [page, setPage] = useState(1)
//   const [companyCount, setCompanyCount] = useState();
//   const [collectSum, setCollectSum] = useState();
//   const [collectMoneySum, setCollectMoneySum] = useState();
//   const [newsList, setNewsList] = useState();
//   const [isRefresh, setIsRefresh] = useState(false)
//   const [dataList, setDataList] = useState([])
//   const [monthDateText, setMonthDateText] = useState(moment(newDate).format("MM/DD")+'일자')
//   const [maxDate, setMaxDate] = useState(new Date(moment(newDate).format("YYYY"), moment(newDate).format("MM"),0).getDate())
//   const [thisMonth, setThisMonth] = useState() //이달의 배출량
//   const [prevMonth, setPrevMonth] = useState() //저번달의 배출량

//   // Select박스 map용
//   const yearSelect = [
//     moment(newDate).format("YYYY") - 3, moment(newDate).format("YYYY") - 2, moment(newDate).format("YYYY") - 1, moment(newDate).format("YYYY")
//   ]

//   // 그래프 데이터 가져오는 용
//   const getDataList = async () => {                
//     if (isFetching || isListEnd) {            
//         return;
//     }        
//     let startYm = '';
//     let endYm = '';
//     let maxY = 1;
    
//     if(newMonth && newDateText){            
//       let month = parseInt(newMonth) < 10 ? '0' + parseInt(newMonth) : newMonth;
//       let dateText = parseInt(newDateText) < 10 ? '0' + parseInt(newDateText) : newDateText;            
//       startYm = newYear +'-'+ month +'-'+ dateText + ' 00:00:00';
//       let date = new Date(startYm);
//       endYm = moment(date.setDate(date.getDate()+1)).format('YYYY-MM-DD') + ' 00:00:00';
//     }else if(newMonth){
//       let month = parseInt(newMonth) < 10 ? '0' + parseInt(newMonth) : newMonth;
//       let dateText = '01';
//       startYm = newYear +'-'+ month +'-'+ dateText + ' 00:00:00';
//       let date = new Date(startYm);
//       maxY =  new Date(newYear, month, 0).getDate()
//       endYm = moment(date.setMonth(date.getMonth()+1)).format('YYYY-MM-DD') + ' 00:00:00';
//     }else{
//       startYm = newYear +'-01-01 00:00:00';
//       endYm = parseInt(newYear) + 1 +'-01-01 00:00:00';
//       maxY =  new Date(newYear, 2, 0).getDate() === 29 ? 366 : 365
//     }

//     console.log("startYm ::", startYm)
//     console.log("endYm ::", endYm)

//     let params={            
//         page : page,            
//         company : selector.auth?.user?.member_group?.[0]?.company_group.id,
//         end_ym : endYm,
//         start_ym : startYm,
//         sum : 1
//     }

//     setIsFetching(true)
    
//     const response = await mainResponse(params);
//     console.log(params)       
//     const item = [
//       {
//           label : '쓰레기량',
//           x : 1,
//           y : response.data?.data || 0,           
//       },
//       {
//           label : '최대 쓰레기량',
//           x : 2,
//           y : maxY * 300 - (response.data?.sum || 0),           
//       }
//     ]
//     console.log("item ::", item)
//     setDataList(item)
//     setIsFetching(false)
//   }

//   // 이달의 배출량, 저번달의 배출량 가져오는 용
//   const getFixedData = async () => {

//     let startYm = parseInt(fixedYear) - 1 +'-01-01 00:00:00';
//     let endYm = parseInt(fixedYear) + 1 +'-01-01 00:00:00';

//     let params={            
//       page : page,            
//       company : selector.auth?.user?.member_group?.[0]?.company_group.id,   
//       end_ym : endYm,
//       start_ym : startYm,
//       sum : 1
//     }

//     const fixedResponse = await mainResponse(params)
//     const fixedItem = fixedResponse.data?.data || 0
//     console.log("fixedItem ::", fixedItem)

//     setThisMonth(fixedItem[0]?.qty)
//     setPrevMonth(fixedItem[1]?.qty)
//   }

//   // 지금 이 시간 웨버는? 가져오는 용
//   const getDashboardData = async () => {
//     const response = await dashboardResponse();    
//     console.log("response.data ::", response.data)    
//     if(response.data?.result === 'success'){
//       const item = response.data?.data
//       setCompanyCount(item.company_count);
//       setCollectSum(item.sum_collect);
//       setCollectMoneySum(item.sum_collect_money);
//     }
//   }

//   // 웨버뉴스! 가져오는 용
//   const getNewsList = async () => {
//     try {
//       const response = await newsResponse();
//       if (response.status === 200) {
//         const newsList = response.data?.results?.slice(0, 9).map(news => ({
//           ...news,
//           write_date: moment(new Date(news.write_date)).format('YYYY-MM-DD HH:mm:ss'),
//           subject: Util.truncateString(Util.replaceHtmlTag(news.subject), 35),
//           content: Util.truncateString(Util.replaceHtmlTag(news.content), 80),
//         }));
//         setNewsList(newsList);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     getDataList();
//     getDashboardData();
//     getNewsList();
//     getFixedData();
//     console.log("설렉터", selector)
//   }, [])

//   // select박스 선택될 때마다 발동되는 useEffect
//   useEffect(() => {
//     if(isRefresh){
//       setIsRefresh(false)
//       getDataList();
//   }
//   }, [isRefresh])

//   // 그래프 그릴 때 쓰는거
//   const qtyArr = () => {
//     let arr = []
//     let subArr = [0, 0, 0]
//     if(newYear == 2022){ 
//       for(let i = dataList[0]?.y.length - 1; i >= 0; i--){
//         arr.push(dataList[0]?.y[i]?.qty)
//       }
//       arr = [...subArr, ...arr]
//     }else{
//       for(let i = dataList[0]?.y.length - 1; i >= 0 ; i--){
//         arr.push(dataList[0]?.y[i]?.qty)
//       }
//       arr = [...arr]
//     }
//     return arr;
//   }

//   // 년도 선택될 때마다 발동되는 useEfeect
//   useEffect(() => {
//     qtyArr()
//   }, [newYear])

//   // 그래프 그릴 때 쓰는거
//   const lineChartData = {
//     labels: [1 + '월', 2 + '월', 3 + '월', 4 + '월', 5 + '월', 6 + '월', 7 + '월', 8 + '월', 9 + '월', 10 + '월', 11 + '월', 12 + '월'],
//     series: [
//       qtyArr()
//     ],
//   };
//   const lineChartOptions = {
//       low: 0,
//       showArea: true,
//   }

//   const handleYearClick = (e) => {
//     setNewYear(e.target.value)
//     setMonthDateText(`${e.target.value}년`),
//     setIsFetching(false),
//     setIsListEnd(false),
//     setDataList([]),
//     setNewDateText(''),
//     setNewMonth(''),
//     setMaxDate(new Date(e.target.value, newMonth,0).getDate()),
//     setPage(1)
//     setIsRefresh(true)    
//   }


//   return (
//     // <React.Fragment>
//       // <div className="page-content">
//       //   <MetaTags>
//       //     <title>홈 | Road Safety Total Operating Center </title>
//       //   </MetaTags>
//       //   <Container fluid>
//       //     <div className="page-title-box">
//       //       <Row className="align-items-center">
//       //         <Col md={8}>
//       //           <h6 className="page-title">웨버 홈</h6>
//       //           <ol className="breadcrumb m-0">
//       //             <li className="breadcrumb-item active">'국내최초' 음식물류 폐기물 스마트 수거 플랫폼</li>
//       //           </ol>
//       //         </Col>

//       //       </Row>
//       //     </div>
//         <>
//           <Row>
//             <Col xl={9}>
//               <Card>
//                 <CardBody>
//                   <div style={{display: 'flex'}}>
//                     <h4 className="card-title mb-4">월별 배출량 (단위: L)</h4>
//                     <select id="yearselect" name="yearselect" className='btn btn-primary dropdown-toggle waves-effect waves-light bg-primary' style={{height: 24, marginLeft: 18, fontSize: 14, padding: "0 6px"}} onChange={(e) => {
//                       handleYearClick(e)
//                       console.log(e.target.value)
//                     }}>
//                         <option value="" selected disabled hidden>년도선택</option>
//                       {yearSelect && yearSelect.map((item, index) => {
//                         return (
//                           <option key={index} value={item}>{item + "년"}</option>
//                         )
//                       })}
//                     </select>
//                   </div>
//                   <Row>
//                     <Col lg={8}>
//                       <div>
//                         <ChartistGraph data={lineChartData} style={{ height: "300px" }} options={lineChartOptions} type={'Line'} />
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       <Row style={{marginTop: 72}}>
//                         <Col md={6}>
//                           <div className="text-center">
//                             <p className="text-muted mb-4">이달의 배출량</p>
//                             <h3>{thisMonth}L</h3>
//                           </div>
//                         </Col>
//                         <Col md={6}>
//                           <div className="text-center">
//                             <p className="text-muted mb-4">저번달의 배출량</p>
//                             <h3>{prevMonth}L</h3>
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col md={3}>
//               <Card className="bg-primary bg-fill" style={{height: 383.2, backgroundImage: {trade-history}}}>
//                 <CardBody>
//                   <div className="text-center text-white py-4" style={{marginTop: 48}}>
//                     <h1 style={{fontWeight: 500}}>거래내역 보기</h1>
//                     <p className="text-white-50 mb-0" style={{marginTop: 36}}>
//                       일자별, 월별 거래명세서를 확인하세요!
//                     </p>
//                     <Link to="trade-history" className="text-white">
//                       <p style={{marginTop: 12}}>View more</p>
//                     </Link>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>

//           <Container fluid>
//             <div className="page-title-box" style={{paddingTop: 0}}>
//               <Row className="align-items-center">
//                 <Col md={12}>
//                   <h6 className="page-title">지금 이 시간 웨버는?</h6>
//                   <ol className="breadcrumb m-0">
//                     <li className="breadcrumb-item active">웨버의 성장을 지켜봐주세요.</li>
//                   </ol>
//                 </Col>
//               </Row>
//             </div>
//           </Container>

//             <Col xl={1} md={6} style={{width: "11.78%"}}>
//               <Card className="mini-stat text-white" style={{backgroundColor: '#C9CACA'}}>
//                 <CardBody>
//                   <div className="mb-4">
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       이 시간 웨버는?
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       수거·처리{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={client} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       고객사 수
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(companyCount)}개{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionAmount} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래량
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectSum)}kg{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionPrice} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래대금
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectMoneySum)}원{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={1} md={6} style={{width: "11.78%"}}>
//               <Card className="mini-stat text-white" style={{backgroundColor: '#C9CACA'}}>
//                 <CardBody>
//                   <div className="mb-4">
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       이 시간 웨버는?
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       재생원료{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionAmount} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래량
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectSum)}kg{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionPrice} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래대금
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectMoneySum)}원{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>

//           <div className="page-title-box" style={{paddingTop: 0}}>
//             <Row className="align-items-center">
//               <Col md={12}>
//                 <h6 className="page-title">웨버뉴스!</h6>
//                 <ol className="breadcrumb m-0">
//                   <li className="breadcrumb-item active">음식물 배출 및 재생원료에 대한 정보를 얻어가세요.</li>
//                 </ol>
//               </Col>
//             </Row>
//           </div>

//           <Row>
//             {newsList && newsList.map((item, index) => { return (
//               <Col xl={4} md={6} key={index}>
//                 <Card className="directory-card">
//                   <CardBody>
//                     <div className="d-flex">
//                       <img
//                         src={item.image_url}
//                         alt="사진"
//                         className="img-fluid img-thumbnail avatar-lg"
//                         style={{width: 6.5 + 'rem'}}
//                       />
//                       <div className="flex-1 ms-3">
//                         <h5 className="text-primary font-size-18 mt-0 mb-1">
//                         {item.subject}
//                         </h5>
//                         <p className="font-size-12 mb-2">{item.created_at.substr(11, 8)}</p>
//                         <p className="mb-0">{item.created_at.substr(0, 10)}</p>
//                       </div>
//                     </div>
//                     <hr />
//                     <p className="mb-0">
//                       <b>{item.content}</b>{" "}
//                       <a href={item.link} target="_blank" className="text-primary">
//                         {" "}
//                         더 보기
//                       </a>
//                     </p>
//                   </CardBody>
//                 </Card>
//               </Col>
//             )})
//             }
//           </Row>
//         </>
//       //   </Container>
//       // </div>

//     // </React.Fragment>
//   )
// }

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

// export default withTranslation()(Dashboard)
