import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { getUserByToken } from "../../../crud/auth.crud";

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
// import {
//   postFakeLogin,
//   postJwtLogin,
//   postSocialLogin,
// } from "../../../helpers/fakebackend_helper"

//const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {   
    const response = yield getUserByToken();    
    console.log("리스폰스", response)
    localStorage.setItem("authUser", JSON.stringify(response.data?.data?.[0]))
    yield put(loginSuccess(response.data?.data?.[0]))

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload }) {
  try {
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess())  
    window.location.href = '/main'
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeLatest(LOGOUT_USER, logoutUser)
}

export default authSaga
