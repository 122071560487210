import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// 로그인
import { getCode, login } from "../../crud/auth.crud"
import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import { useSelector } from 'react-redux';

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-sm.png";
import weaver from "../../assets/images/weaver.png";

const Login = props => {
  const [userId, setUserId] = useState('jwchun')
  const [password, setPassword] = useState('qwer1234')
  const selector = useSelector(state => state)
  const history = useHistory()
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {    
    //console.log(values)
    // const url = {
    //   pathname : `/dashboard`,
    //   // state : {...prevState, ...values} 
    // }
    // if(memberId && values.memberPassword == values.memberPasswordConfirm){
    //   console.log("if문 확인")
    //   history.push(url);   
    // }
  }

  const checkLogin = async ()=>{
    const params = {
        user_id : userId,
        password : password,
    }
    console.log('37: ', params)
    const rs = await login(params)
    console.log("39: ",rs)
    console.log(rs.data?.result)
    if(rs.data?.result === 'success'){
      console.log("user :::",rs.data?.data)
      console.log("history :::",props.history)
      props.loginUser(rs.data?.data, props.history)
      const response = await getCode()
      console.log(response.data)
      store.dispatch({ type: SET_CODE, data: [response.data]})
    }else{
      alert('아이디 또는 비밀번호가 일치하지 않습니다.') 
    }    
  }
  const [isLogin, setIsLogin] = useState(false);

  useEffect(()=>{    
    const loginData = localStorage.getItem('authUser');
    if(loginData){
        history.push('/dashboard')
    }    
  },[isLogin])

  return (
    <React.Fragment>
      <MetaTags>
        <title>로그인 | Road Safety Total Operating Center </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                    환영합니다 !
                        </h5>
                    <p className="text-white-50">
                      로그인 해주세요.
                        </p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3 loginInput">
                        <AvField
                          name="userId"
                          label="아이디"
                          value="jwchun"
                          className="form-control"
                          placeholder="아이디를 입력해주세요."
                          type="text"
                          required
                          onChange={(e) => {
                            setUserId(e.target.value)
                          }}
                        />
                      </div>

                      <div className="mb-3 loginInput">
                        <AvField
                          name="password"
                          label="비밀번호"
                          value="qwer1234"
                          type="password"
                          required
                          placeholder="비밀번호를 입력해주세요."
                          onChange={(e) => {
                            setPassword(e.target.value)
                          }}
                        />
                      </div>

                      <Row className="mb-3">
                        <Col sm={6}>
                          <div className="col-12" style={{marginTop: 6}}>
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> 아이디/비밀번호 찾기
                          </Link>
                        </div>
                          {/* <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customControlInline" />
                            <label className="form-check-label" htmlFor="customControlInline">아이디 기억하기</label>
                          </div> */}
                        </Col>
                        <Col sm={6} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {checkLogin()}}
                          >                            
                              로그인
                              </button>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-0 row">
                      </Row>
                      
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {/* Don&#39;t have an account ?{" "} */}
                  계정이 없으십니까?
                  <Link
                    to="terms"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    지금 계정을 만드세요.{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Wasteverse
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.auth
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}