import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useContext } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import { loginUser, apiError } from "../../store/actions"

import intro1 from "../../assets/images/intro1.gif";
import intro2 from "../../assets/images/intro2.gif";
import intro3 from "../../assets/images/intro3.gif";
import logo from "../../assets/images/main_logo.png";
import leftTruck from "../../assets/images/left_truck.png";
import googlePlayStore from "../../assets/images/google_play_store.jpg";
import appleStore from "../../assets/images/apple_store.jpg";
import { useSelector } from 'react-redux';

import "../../assets/scss/ropik-custom.scss"
import MapContext from '../Maps/MapContext'
import Map from '../Maps/Map'

const settings = {
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  fade: true,
  lazyLoad: 'progressive',
  arrows: false,
  dots: false,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: '<div className="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
  nextArrow: '<div className="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
  responsive: [
    {
      breakpoint: 992,
      settings: {
        dots: false,
        arrows: false
      }
    }
  ]
};

const sliderData = [
  { title1: 'GLOBAL SMART', title2: 'ENVIROMENT PLATFORM', text: "RSTOC", src: intro1 },
  { title1: 'GLOBAL SMART', title2: 'ENVIROMENT PLATFORM', text: "RSTOC", src: intro2 },
  { title1: 'GLOBAL SMART', title2: 'ENVIROMENT PLATFORM', text: "RSTOC", src: intro3 }
];

const Main = props => {
  const selector = useSelector(state => state)
  const [isLogin, setIsLogin] = useState(false);
  const [isCollapseShow, setIsCollapseShow] = useState(false);
  const history = useHistory()
  const handleClick = (page) => {
    const url = {
      pathname: `/${page}`
    }
    history.push(url);
  }

  const { map } = useContext(MapContext)

  useEffect(() => {

    console.log("설렉터 ::", selector)
    const loginData = localStorage.getItem('authUser');
    if (loginData) {
      setIsLogin(true)
    }
  }, [isLogin])

  function WeatherScript() {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://app1.weatherwidget.org/js/?id=ww_2cf4b1f3ae211";
      script.async = true;
      document.body.appendChild(script);
    });

    return <p />;
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>RSTOC</title>
      </MetaTags>
      <header id="main-header" className="">
        <div className="main-header">
          <div className="container-fluid main-container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <button id="toggle-btn" className="navbar-toggler c-toggler collapsed" onClick={() => { setIsCollapseShow(true) }}>
                    <div data-toggle="collapse" className="navbar-toggler-icon">
                      <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                    </div>
                  </button>
                  <a href="" aria-current="page" className="navbar-brand router-link-exact-active router-link-active">
                    <img src={logo} alt="wasteverse" className="img-fluid logo" />
                  </a>
                  <div id="navbarSupportedContent" className={isCollapseShow ? 'navbar-collapse collapse show' : 'navbar-collapse collapse'}>
                    <div className="menu-main-menu-container">
                      <ul id="top-menu" className="navbar-nav ml-auto">
                        {isLogin &&
                          <li className="menu-item">
                            <a onClick={() => { handleClick('dashboard') }}>위험물 리스트</a>
                          </li>
                        }
                        <li className="menu-item">
                          <a onClick={() => { handleClick('chart') }}>위험물 통계</a>
                        </li>
                        <li className="menu-item">
                          <a onClick={() => { alert('준비중입니다.') }}>디바이스 조회</a>
                        </li>
                        <li className="menu-item">
                          <a onClick={() => { alert('준비중입니다.') }}>문의하기</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                <div className="nav-overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id='map' style={{ width: '100%', height: '100%', position: 'absolute' }}>
        <Map />
      </div>
      <div id='weather' style={{ position: 'absolute', bottom: '0', width: '100%' }}>
        <div id="ww_2cf4b1f3ae211" v='1.3' loc='id' a='{"t":"responsive","lang":"ko","sl_lpl":1,"ids":[],"font":"Arial","sl_ics":"one_a","sl_sot":"celsius","cl_bkg":"image","cl_font":"#FFFFFF","cl_cloud":"#FFFFFF","cl_persp":"#81D4FA","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722"}'>
          Weather Data Source:
          <a href="https://wetterlabs.de/wetter_busan/woche/" id="ww_2cf4b1f3ae211_u" target="_blank">wettervorhersage 7 tage</a>
        </div>
        <WeatherScript />
      </div>
      <div id='hazardList' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '30px', position: 'absolute', top: '15%', left: '1%', width: '25%', height: '70%' }}>
        <div style={{ padding: '7%' }}>
          <p style={{ color: '#fff', fontSize: '1.7em' }}>탐지된 위험물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.7em' }}>518개</p>
          <p style={{ color: '#fff', fontSize: '1.7em' }}>정밀 탐지 완료 된 위험물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.7em' }}>80개</p>
          <p style={{ color: '#fff', fontSize: '1.7em' }}>처리 중 위험물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.7em' }}>18개</p>
          <p style={{ color: '#fff', fontSize: '1.7em' }}>처리 완료 위험물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.7em' }}>5개</p>
          <p style={{ color: '#fff', fontSize: '1.7em' }}>당일 탐지 위험물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.7em' }}>81개</p>
          <p style={{ color: '#fff', fontSize: '1.7em' }}>현장 확인필요 위험물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.7em' }}>5개</p>
        </div>

      </div>

      <div id='hazardList' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '30px', position: 'absolute', top: '30%', left: '74%', width: '25%', height: '30%', display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '50%', padding: '10%' }}>
          <p style={{ color: '#fff', fontSize: '1.4em' }}>포트홀</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.4em' }}>400개</p>
          <p style={{ color: '#fff', fontSize: '1.4em' }}>도로파열/균열</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.4em' }}>18개</p>
          <p style={{ color: '#fff', fontSize: '1.4em' }}>파손 도로시설물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.4em' }}>81개</p>
        </div>
        <div style={{ width: '50%', padding: '10%' }}>
          <p style={{ color: '#fff', fontSize: '1.4em' }}>공유자전거</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.4em' }}>8개</p>
          <p style={{ color: '#fff', fontSize: '1.4em' }}>공유킥보드</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.4em' }}>1개</p>
          <p style={{ color: '#fff', fontSize: '1.4em' }}>도로낙하물</p>
          <p style={{ textAlign: 'right', color: '#fff', fontSize: '1.4em' }}>5개</p>
        </div>

      </div>
      {/* <div className="account-pages my-5 pt-sm-5" onClick={()=>{setIsCollapseShow(false)}}>
            <div className="main-content-wrap">
                <section id="iq-favorites">
                    <div id="map" className="iq-main-header d-flex align-items-center justify-content-between" style={{width: '100%', height: '100%'}}>
                            </div>
                    </section>
            </div>            
        </div> */}
      {/* <footer id="contact_footer" className="footer-one iq-bg-dark"> 
            <div className="footer-top">
                <div className="container-fluid main-container-fluid">
                    <div className="row footer-standard">
                        <div className="col-lg-7">
                            <div className="widget text-left">
                                <div className="menu-footer-link-1-container">
                                    <ul id="menu-footer-link-1" className="menu p-0">
                                        <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314">
                                            <h2 className='main-h2'>
                                                MROCOMMERCE
                                            </h2>
                                        </li>                                    
                                    </ul>
                                </div>
                            </div>
                            <div className="widget text-left">
                                <div className="textwidget">
                                    <p><small>MRO DISTRIBUTION & BEST SERVICE LEADER IN THE WORLD</small></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 mt-4 mt-lg-0">
                            <h6 className="main-h6 footer-link-title">
                                Follow Us :
                            </h6>
                            <ul className="info-share">
                                <li><a target="_blank" href="#"><i className="fab fa-facebook"></i></a></li>
                                <li><a target="_blank" href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a target="_blank" href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                <li><a target="_blank" href="#"><i className="fab fa-github"></i></a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                            <div className="widget text-left">
                                <div className="textwidget">
                                    <h6 className="main-h6 footer-link-title">Wasteverse App</h6>
                                    <div className="d-flex align-items-center">
                                        <a className="app-image" href="#" style={{marginRight: '10px'}}>
                                            <img src={googlePlayStore} alt="play-store"/>
                                        </a>
                                        <a className="ml-3 app-image" href="#"><img src={appleStore} alt="app-store"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </footer> */}
    </React.Fragment>
  )
}

export default Main;
// const mapStateToProps = state => {
//   const { error } = state.Login
//   return { error }
// }

// export default withRouter(
//   connect(mapStateToProps, { loginUser, apiError })(Main)
// )

// Main.propTypes = {
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
// }