import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import PropTypes from 'prop-types'
import { FormGroup } from "reactstrap"

import { connect } from "react-redux"
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  showSettingbarAction,
} from "../../store/actions"

// availity-reactstrap-validation
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import store from "../../store"
import { SET_AUTH, CLEAR_AUTH } from "../../store/auth/profile/actionTypes"
import { LOGOUT_USER } from "store/auth/login/actionTypes"
import { CLEAR_DATA } from "store/auth/data/actionTypes"
// import PURGE
import persistor from "index"

//SimpleBar
import SimpleBar from "simplebar-react"

import { Link, useHistory } from "react-router-dom"

//Import images
import arrowRight from "../../assets/images/arrow_right.png";
import logOut from "../../assets/images/logout.png";
import { settingChange, settingConfirm } from "crud/auth.crud"

const RightSidebar = props => {
  const history = useHistory()
  const selector = useSelector(state => state)
  console.log(selector)
  console.log(selector.Profile?.auth?.[0]?.agree_email)

  const [memberId, setMemberId] = useState(selector.Profile?.auth?.[0]?.id || selector.auth?.user?.id)
  const [email, setEmail] = useState(selector.Profile?.auth?.[0]?.agree_email || selector.auth?.user?.agree_email || "N")
  const [push, setPush] = useState(selector.Profile?.auth?.[0]?.agree_push || selector.auth?.user?.agree_push || "N")

  const changePreference = async () => {
    const params = {
        agree_email : email,
        agree_push : push
    }
    const url = memberId
    const rs = await settingChange(params, url);     
    console.log("rs >>>", rs)   
    if(rs.status === 200){
        const urlPlus = selector.auth?.user?.id;
        const memberResponse = await settingConfirm(urlPlus);
        console.log([memberResponse.data])
        await store.dispatch({ type: SET_AUTH, data: [memberResponse.data] }); 
        // SimpleToast.show('정상적으로 변경되었습니다.', SimpleToast.SHORT);
        alert("정상적으로 변경되었습니다.")
    }
  }

  const handleLogout = async() => {
    localStorage.removeItem('ACCESS_TOKEN')
    localStorage.removeItem('authUser');
    store.dispatch({type: CLEAR_AUTH})
    store.dispatch({type: LOGOUT_USER})
    store.dispatch({type: CLEAR_DATA})
    await persistor.purge()
    //history.push('main')
  }

  useEffect(()=>{

  }, [])

  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.showSettingbarAction(false)
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">설정</h5>
            </div>

            <hr className="my-0" />
                  
            <div className="p-4">
              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between"}}>
                <span className="mb-2 d-block">이메일 수신 동의</span>
                <input type="checkbox" className="is-untouched is-pristine av-valid form-check-input" defaultChecked={email == "Y"} onChange={(e)=>{
                  setEmail(e.target.checked ? "Y" : "N")
                }} />
              </div>

              <hr className="mt-1" />

              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between"}}>
                <span className="mb-2 d-block">앱 푸시 알람 수신 동의</span>
                <input type="checkbox" className="is-untouched is-pristine av-valid form-check-input" defaultChecked={push == "Y"} onChange={(e)=>{
                  setPush(e.target.checked ? "Y" : "N")
                }} />
              </div>
              <hr className="mt-1" />

              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between"}}>
                <span className="mb-2 d-block">WASTEVERSE 버전</span>
                <span className="mb-2 d-block">1.0</span>
              </div>
              <hr className="mt-1" />

              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between", cursor: "pointer"}} onClick={(e)=>{
                e.preventDefault()
                props.showSettingbarAction(false)
                handleLogout()
              }}>
                <span className="mb-2 d-block">로그아웃 하기</span>
                <img src={logOut} width={16} height={16} />
              </div>
              <hr className="mt-1" />

              <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6, width: "100%"}} onClick={() => {
                changePreference()
              }} value="변경하기" />

              <input type="button" className="btn btn-dark-mint waves-effect waves-light" style={{height: 35, marginTop: 18, marginLeft: 6, width: "100%"}} onClick={() => {
                // changePreference()
              }} value="회원 탈퇴" />

              {/* {props.layoutType === "vertical" ? (
                <React.Fragment>
                  <hr className="mt-1" />
                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      WASTEVERSE 버전{" "}
                    </span>
                    <input
                      type="radio"
                      id="sidebarDefault"
                      name="sidebarType"
                      value="default"
                      checked={props.leftSideBarType === "default"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value)
                        }
                      }}
                    />
                    <label htmlFor="sidebarDefault">Default</label>
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarCompact"
                      name="sidebarType"
                      value="compact"
                      checked={props.leftSideBarType === "compact"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value)
                        }
                      }}
                    />
                    <label htmlFor="sidebarCompact">Compact</label>
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarIcon"
                      name="sidebarType"
                      value="icon"
                      checked={props.leftSideBarType === "icon"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value)
                        }
                      }}
                    />
                    <label htmlFor="sidebarIcon">Icon</label>
                  </div>

                  <hr className="mt-1" />

                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      로그아웃 하기
                    </span>
                    <input
                      type="radio"
                      id="leftsidebarThemelight"
                      name="leftsidebarTheme"
                      value="light"
                      checked={props.leftSideBarTheme === "light"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarTheme(e.target.value)
                        }
                      }}
                    />

                    <label htmlFor="leftsidebarThemelight">Light</label>
                    {"   "}
                    <input
                      type="radio"
                      id="leftsidebarThemedark"
                      name="leftsidebarTheme"
                      value="dark"
                      checked={props.leftSideBarTheme === "dark"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarTheme(e.target.value)
                        }
                      }}
                    />

                    <label htmlFor="leftsidebarThemedark">Dark</label>
                    {"   "}
                    <input
                      type="radio"
                      id="leftsidebarThemecolored"
                      name="leftsidebarTheme"
                      value="colored"
                      checked={props.leftSideBarTheme === "colored"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarTheme(e.target.value)
                        }
                      }}
                    />

                    <label htmlFor="leftsidebarThemecolored">Colored</label>
                  </div>
                  <hr className="mt-1" />
                </React.Fragment>
              ) : null} */}

              {/* <h6 className="text-center">Choose Layouts</h6>

              <div className="mb-2">
                <Link
                  to="//veltrix-v.react.themesbrand.com"
                  target="_blank"
                >
                  <img
                    src={layout1}
                    className="img-fluid img-thumbnail"
                    alt=""
                  />
                </Link>
              </div>

              <div className="mb-2">
                <Link to="//veltrix-v-dark.react.themesbrand.com" 
                target="_blank">
                  <img
                    src={layout2}
                    className="img-fluid img-thumbnail"
                    alt=""
                  />
                </Link>
              </div>

              <div className="mb-2">
                <Link to="//veltrix-v-rtl.react.themesbrand.com" 
                target="_blank">
                  <img
                    src={layout3}
                    className="img-fluid img-thumbnail"
                    alt=""
                  />
                </Link>
              </div>

              <Link
                to="#"
                className="btn btn-primary btn-block mt-3"
                target="_blank"
              >
                <i className="mdi mdi-cart ms-1" /> Purchase Now
              </Link> */}
            </div>
          </div>
        </SimpleBar>
      </div>
      <Link
        style={{cursor: "auto"}}
        to="#"
        onClick={e => {
          e.preventDefault()
          props.showSettingbarAction(false)
        }}
        className="rightbar-overlay"
      />
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showSettingbarAction: PropTypes.func,
  topbarTheme: PropTypes.any
}

const mapStateToProps = state => {
  return { ...state.Layout }
}

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  showSettingbarAction,
})(RightSidebar)
